<template>
  <div class="home overflow-x-hidden">
    <img
      src="@/assets/background/background_main.png"
      class="w-full min-w-[825px] h-auto fixed -z-50"
      alt=""
    />
    <!-- Header -->
    <div
      id="header"
      class="h-[90px] bg-transparent container px-[15px] mx-auto flex justify-between items-center"
    >
      <router-link
        to="/"
        class="hidden sm:block cursor-pointer text-h3-bold md:text-h2-bold text-sk-default2"
      >
        정책공유AI플랫폼
      </router-link>
      <div class="ml-auto flex h-full items-center">
        <div
          @click="this.$refs.policy.scrollIntoView({ behavior: 'smooth' })"
          class="hidden md:flex cursor-pointer text-h3-bold text-sk-default2 h-full px-[35px] transition-all duration-300 hover:text-sk-primary hover:border-b-4 border-sk-primary items-center justify-center"
        >
          <p>정책</p>
        </div>
        <div
          @click="this.$refs.news.scrollIntoView({ behavior: 'smooth' })"
          class="hidden md:flex cursor-pointer text-h3-bold text-sk-default2 h-full px-[35px] transition-all duration-300 hover:text-sk-primary hover:border-b-4 border-sk-primary items-center justify-center"
        >
          <p>뉴스</p>
        </div>
        <div
          @click="this.$refs.education.scrollIntoView({ behavior: 'smooth' })"
          class="hidden md:flex cursor-pointer text-h3-bold text-sk-default2 h-full px-[35px] transition-all duration-300 hover:text-sk-primary hover:border-b-4 border-sk-primary items-center justify-center"
        >
          <p>교육</p>
        </div>
        <div
          @click="$router.push('/chat')"
          class="flex cursor-pointer text-h3-bold animate-pulse h-full px-[35px] transition-all duration-300 text-sk-primary hover:border-b-4 border-sk-primary items-center justify-center"
        >
          <p>AI대변인</p>
        </div>
      </div>
    </div>
    <div class="bg-transparent container px-[15px] mx-auto" id="my-title">
      <p
        class="text-title-36 lg:text-title-52 text-sk-default2 pt-[83px] md:pt-[10vw] mb-[7px]"
      >
        정책공유플랫폼
      </p>
      <p
        class="text-h3-regular lg:text-h1-regular text-sk-default2 pb-[60px] lg:pb-[9.5vw] truncate"
      >
        당신의 목소리, 정치의 미래를 만듭니다<br />함께가면 길이 됩니다
      </p>
    </div>
    <div ref="policy"></div>
    <div class="bg-white w-full pt-[70px] pb-[120px] relative z-10">
      <img
        src="@/assets/background/Ellipse1.svg"
        class="absolute top-0 right-0 z-0"
      />
      <img
        src="@/assets/background/Ellipse2.svg"
        class="absolute bottom-0 left-0 z-0"
      />
      <div class="container mx-auto slide-in-bottom px-[10px] relative z-30">
        <div>
          <div class="flex gap-[16px] items-center">
            <div class="w-[4px] h-[50px] bg-sk-primary"></div>
            <div class="text-h1-bold md:text-title-36 md:!text-[42px]">
              정책
            </div>
          </div>
          <div class="mt-[40px] grid grid-cols-1 xl:grid-cols-2 gap-[55px]">
            <div>
              <p class="text-h3-bold md:text-h2-bold">주제별 정책</p>
              <div class="mt-[20px] flex gap-[14px] flex-wrap">
                <div
                  v-for="(PolicyTag, i) in groupPolicyTags"
                  :key="i"
                  @click="groupPolicyTagValue = PolicyTag"
                  class="cursor-pointer px-[12px] sm:px-[30px] py-[6px] text-body3-medium sm:text-body2-medium rounded-[100px]"
                  :class="[
                    groupPolicyTagValue === PolicyTag
                      ? 'bg-sk-primary text-white !font-bold'
                      : 'border border-sk-icon-sub hover:bg-sk-box001',
                  ]"
                >
                  {{ PolicyTag }}
                </div>
              </div>
              <div
                class="mt-[30px] flex flex-col sm:flex-row gap-[30px] sm:gap-[14px]"
              >
                <div class="border border-[#DBDBDB] rounded-[20px] basis-1/2">
                  <div
                    class="w-full rounded-t-[20px] h-[244px] bg-top bg-cover"
                    :style="{ 'background-image': 'url(' + Urls.group1 + ')' }"
                  ></div>
                  <div class="flex flex-col pt-[16px] px-[20px] pb-[25px]">
                    <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-sk-subScience px-[13px] py-[2px]"
                    >
                      산업 ∙ 노동
                    </div>
                    <p class="text-body1-semibold mt-[14px] ellipsis-2">
                      농공단지 지원법제 정비방안
                    </p>
                    <p
                      class="text-body2-medium text-sk-disabled ellipsis-4 mt-[52px]"
                    >
                      최근 농공단지가 지방경제 활성화 역할 감당에 어려움을 겪고
                      있는 가운데, 농공단지 지원법제 정비방안 마련을 위한 정책
                      토론회가 2일 국회의원회관 제2소회의실에서 개최됐다. 이번
                      토론회는 농림축산식품해양수산위원회 홍문표
                      국회의원(예산·홍성, 국민의힘)과
                      (사)한국농공단지연합회(회장 한기흥),
                      (사)한국국가법학회(회장 조성규)가 공동으로 주최했고,
                      강훈식·신정훈·어기구·이장섭·정일영 국회의원 주관으로
                      진행됐다. ‘혁신 및 입주기업의 경쟁력 강화를 위한 농공단지
                      지원법제 정비방안’을 주제로 열린 이번 정책 토론회는 전국의
                      농공단지 활성화와 지역발전 전략거점시설로의 역할 강화,
                      현실에 맞는 농공단지 대전환 전략을 위해 마련됐다. 토론회는
                      전문가 주제발표와 더불어 학계, 법 전문가, 농업인단체 등이
                      참여한 지정토론으로 진행됐다.
                    </p>
                  </div>
                </div>
                <div class="border border-[#DBDBDB] rounded-[20px] basis-1/2">
                  <div
                    class="w-full rounded-t-[20px] h-[244px] bg-center bg-cover"
                    :style="{ 'background-image': 'url(' + Urls.group2 + ')' }"
                  ></div>
                  <div class="flex flex-col pt-[16px] px-[20px] pb-[25px]">
                    <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-sk-subKorean px-[13px] py-[2px]"
                    >
                      문화 ∙ 체육 ∙ 관광
                    </div>
                    <p class="text-body1-semibold mt-[14px] ellipsis-2">
                      힘쎈 충남! 문화관광도시 충남에 창의를 더할 문화 예술 교육
                      포럼
                    </p>
                    <p
                      class="text-body2-medium text-sk-disabled ellipsis-4 mt-[24px]"
                    >
                      이번 포럼은 문화예술분야 관계자가 한 자리에 모여
                      ‘문화관광도시 충남에 창의를 더할 문화예술교육’을 주제로
                      충남 문화예술교육의 새로운 발전모델을 제시하고 그 방향성에
                      대해 논의한다. 주요내용으로는 올해 문체부에서 발표한
                      ‘제2차 문화예술교육 종합계획’을 통해 ‘문화예술교육
                      전용공간의 필요성’과 ‘전문인력 범위 확대’에 관해 논의하고,
                      세계 4대 종합예술 축제를 중심으로 문화예술교육 공간이 가진
                      의미에 대해 조명한다. 또, 인공지능을 더한 문화예술
                      창작사례 발표를 통해 앞으로 문화예술교육의 발전방향과
                      다양한 학문 연계 가능성을 모색한다.
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div
                class="w-[156px] py-[9px] mx-auto mt-[30px] md:mt-[68px] cursor-pointer border border-sk-border rounded-[100px] hover:bg-sk-box001 flex items-center justify-center gap-[14px]"
              >
                <p class="text-body2-medium text-sk-sub">전체 더보기</p>
                <img src="@/assets/icons/more.svg" alt="더보기" />
              </div> -->
            </div>
            <div>
              <p class="text-h3-bold md:text-h2-bold">지역별 정책</p>
              <div class="mt-[20px] flex gap-[14px] flex-wrap">
                <div
                  v-for="(PolicyTag, i) in regionPolicyTags"
                  :key="i"
                  @click="regionPolicyTagValue = PolicyTag"
                  class="cursor-pointer px-[12px] sm:px-[30px] py-[6px] text-body3-medium sm:text-body2-medium rounded-[100px]"
                  :class="[
                    regionPolicyTagValue === PolicyTag
                      ? 'bg-sk-primary text-white !font-bold'
                      : 'border border-sk-icon-sub hover:bg-sk-box001',
                  ]"
                >
                  {{ PolicyTag }}
                </div>
              </div>
              <div
                class="mt-[30px] flex flex-col sm:flex-row gap-[30px] sm:gap-[14px]"
              >
                <div class="border border-[#DBDBDB] rounded-[20px] basis-1/2">
                  <div
                    class="w-full rounded-t-[20px] h-[244px] bg-center bg-cover"
                    :style="{ 'background-image': 'url(' + Urls.region1 + ')' }"
                  ></div>
                  <div class="flex flex-col pt-[16px] px-[20px] pb-[25px]">
                    <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-[#5DBA68] px-[13px] py-[2px]"
                    >
                      홍성 ∙ 예산
                    </div>
                    <p class="text-body1-semibold mt-[14px] ellipsis-2">
                      2024년 예산‧홍성군 관련 10개 총사업비 8765 억 원 반영 성과
                    </p>
                    <p
                      class="text-body2-medium text-sk-disabled ellipsis-4 mt-[24px]"
                    >
                      국회 심의를 통해 반영시킨 주요 신규사업으론 서해선복선전철
                      삽교역 주변 내포 농생명 그린바이오 클러스터 단지내
                      추진중인 ▲내포 그린바이오 벤처 캠퍼스(총사업비 213억)7억
                      ▲신삽교역 연계 그린바이오 생체활성제품 글로벌사업화
                      지원(총사업비 460억) 30억 예산을 반영시켜 내포신도시와
                      예산군 경제 활성화에 큰 도움이 될 것으로 기대된다.
                    </p>
                  </div>
                </div>
                <div class="border border-[#DBDBDB] rounded-[20px] basis-1/2">
                  <div
                    class="w-full rounded-t-[20px] h-[244px] bg-center bg-cover"
                    :style="{ 'background-image': 'url(' + Urls.region2 + ')' }"
                  ></div>
                  <div class="flex flex-col pt-[16px] px-[20px] pb-[25px]">
                    <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-[#9866D9] px-[13px] py-[2px]"
                    >
                      공주 ∙ 부여 ∙ 청양
                    </div>
                    <p class="text-body1-semibold mt-[14px] ellipsis-2">
                      보령 신규사업 다수 반영
                    </p>
                    <p
                      class="text-body2-medium text-sk-disabled ellipsis-4 mt-[52px]"
                    >
                      보령시는 ▲ 총사업비 142억 원에 달하는 공공폐수처리시설
                      설치 웅천산단 (5억 원) 사업과 ▲ 전국해양스포츠제전 개최
                      사업, 그리고 소상공인들의 숙원사업이었던 ▲
                      소상공인시장진흥공단 보령센터 건립 등 신규사업을 다수
                      반영시키는 성과를 냈다.
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div
                class="w-[156px] py-[9px] mx-auto mt-[30px] md:mt-[68px] cursor-pointer border border-sk-border rounded-[100px] hover:bg-sk-box001 flex items-center justify-center gap-[14px]"
              >
                <p class="text-body2-medium text-sk-sub">전체 더보기</p>
                <img src="@/assets/icons/more.svg" alt="더보기" />
              </div> -->
            </div>
          </div>
        </div>
        <div ref="news"></div>
        <div class="mt-[60px] md:mt-[120px]">
          <div class="flex gap-[16px] items-center">
            <div class="w-[4px] h-[50px] bg-sk-primary"></div>
            <div class="text-h1-bold md:text-title-36 md:!text-[42px]">
              뉴스
            </div>
          </div>
          <div class="mt-[40px] grid grid-cols-1 xl:grid-cols-2 gap-[55px]">
            <div>
              <p class="text-h3-bold md:text-h2-bold">영상</p>
              <div
                class="mt-[23px] grid grid-cols-1 sm:grid-cols-2 gap-[20px] md:gap-x-[50px] md:gap-y-[35px]"
              >
                <div
                  v-for="(videoNewsItem, i) in videoNewsList"
                  :key="i"
                  class="flex flex-col"
                >
                  <div
                    class="w-full h-[238px]"
                    v-html="videoNewsItem.iframe"
                  ></div>
                  <div
                    class="flex justify-between max-w-[317px] mt-[16px] items-center"
                  >
                    <p
                      class="text-body2-bold md:text-body1-semibold truncate max-w-[210px]"
                    >
                      {{ videoNewsItem.title }}
                    </p>
                    <p class="text-body2-medium text-sk-info">
                      {{ videoNewsItem.date }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div
                class="w-[156px] py-[9px] mx-auto mt-[30px] md:mt-[68px] cursor-pointer border border-sk-border rounded-[100px] hover:bg-sk-box001 flex items-center justify-center gap-[14px]"
              >
                <p class="text-body2-medium text-sk-sub">전체 더보기</p>
                <img src="@/assets/icons/more.svg" alt="더보기" />
              </div> -->
            </div>
            <div>
              <p class="text-h3-bold md:text-h2-bold">카드 뉴스</p>
              <div class="mt-[23px] grid grid-cols-1 sm:grid-cols-2 gap-[24px]">
                <div
                  v-for="(cardNewsItem, i) in cardNewsList"
                  :key="i"
                  class="flex flex-col rounded-[15px] border border-[#DBDBDB]"
                >
                  <div
                    @click="cardNewsList[i].open = true"
                    class="w-full h-[235px] cursor-pointer bg-top bg-cover rounded-t-[15px]"
                    :style="{
                      'background-image': 'url(' + cardNewsItem.imgUrl + ')',
                    }"
                  ></div>
                  <div class="pt-[10px] px-[20px] pb-[10px]">
                    <!-- <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-sk-subScience px-[13px] py-[2px]"
                    >
                      {{ cardNewsItem.tag }}
                    </div> -->
                    <p class="text-body1-semibold ellipsis-2">
                      {{ cardNewsItem.title }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div
                class="w-[156px] py-[9px] mx-auto mt-[30px] md:mt-[68px] cursor-pointer border border-sk-border rounded-[100px] hover:bg-sk-box001 flex items-center justify-center gap-[14px]"
              >
                <p class="text-body2-medium text-sk-sub">전체 더보기</p>
                <img src="@/assets/icons/more.svg" alt="더보기" />
              </div> -->
            </div>
          </div>
        </div>
        <div ref="education">
          <div class="flex gap-[16px] items-center mt-[60px] md:mt-[120px]">
            <div class="w-[4px] h-[50px] bg-sk-primary"></div>
            <div class="text-h1-bold md:text-title-36 md:!text-[42px]">
              교육
            </div>
          </div>
          <div
            class="mt-[40px] flex flex-col 2xl:flex-row justify-between gap-[30px]"
          >
            <div>
              <p class="text-h3-bold md:text-h2-bold pl-[10px]">국회 이야기</p>
              <div
                class="md:w-[469px] md:h-[278px] mt-[16px] border bg-sk-box002 border-sk-border rounded-[20px] pt-[39px] pb-[60px] pl-[31px] bg-cover bg-right-bottom"
                :style="{ 'background-image': 'url(' + whatUrl + ')' }"
              >
                <p class="text-h2-bold md:text-h1-bold md:!font-semibold">
                  무슨 일을 할까요?
                </p>
                <p class="text-h3-regular mt-[24px]">
                  국회에서는<br />
                  어떤 일을 하는 지<br />
                  함께 알아볼까요?
                </p>
              </div>
            </div>
            <div>
              <p class="text-h3-bold md:text-h2-bold pl-[10px]">콘텐츠</p>
              <div class="flex flex-col sm:flex-row gap-[24px]">
                <div
                  class="sm:w-[430px] mt-[16px] border bg-sk-box002 border-sk-border rounded-[20px] pt-[30px] pl-[30px] h-[278px]"
                >
                  <p class="text-h2-bold md:text-h1-bold md:!font-semibold">
                    국회는 어떤 역사를 <br />
                    가지고 있나요?
                  </p>
                  <p class="text-h3-regular mt-[24px]">
                    국회의 역사를 <br />
                    알아볼까요?
                  </p>
                </div>
                <div
                  class="sm:w-[430px] mt-[16px] border bg-sk-box002 border-sk-border rounded-[20px] pt-[30px] pl-[30px] h-[278px]"
                >
                  <p class="text-h2-bold md:text-h1-bold md:!font-semibold">
                    국회에는 어떤 사람이 <br />
                    있을까요?
                  </p>
                  <p class="text-h3-regular mt-[24px]">
                    국회의 구성원을 <br />
                    알아볼까요?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-for="(cardNewsItem, i) in cardNewsList" :key="i">
      <div v-if="cardNewsList[i].open === true">
        <div class="modal-bg"></div>
        <div
          class="modal-box flex flex-col items-center shadow-xl"
          @blur="cardNewsList[i].open = false"
        >
          <div class="modal-closebtn" @click="cardNewsList[i].open = false">
            <img src="@/assets/icons/close.svg" />
          </div>
          <div class="modal-img">
            <img :src="cardNewsItem.imgUrl" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      whatUrl: require('@/assets/background/what.png'),
      Urls: {
        group1: require('@/assets/background/policy1.jpeg'),
        group2: require('@/assets/background/policy2.jpeg'),
        region1: require('@/assets/background/region1.png'),
        region2: require('@/assets/background/region2.png'),
      },
      groupPolicyTagValue: '전체',
      groupPolicyTags: [
        '전체',
        '가족 ∙ 여성  ∙  청소년',
        '경제 ∙ 금융 ∙ 공정거래',
        '과학 ∙ 교육',
        '문화 ∙ 체육 ∙ 관광',
        '국방  ∙ 안보',
        '중소기업',
        '산업  ∙ 노동',
      ],
      regionPolicyTagValue: '전체',
      regionPolicyTags: [
        '전체',
        '천안  ∙ 아산',
        '당진  ∙ 서산 ∙ 태안 ∙ 보령 ∙ 서천',
        '홍성 ∙ 예산',
        '공주 ∙ 부여 ∙ 청양',
        '계룡 ∙ 논산 ∙ 금산',
      ],
      videoNewsList: [
        {
          imgUrl: require('@/assets/background/video1.png'),
          iframe:
            '<iframe width="316" height="238" src="https://www.youtube.com/embed/HpOkZ90NaW4?si=WZE8mtBifOmgOK_o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
          title: '1월 12일 한동훈 비상대책위원장, 대한불교조계종 통도사 예방',
          date: '2024. 1. 12.',
        },
        {
          imgUrl: require('@/assets/background/video2.png'),
          iframe:
            '<iframe width="316" height="238" src="https://www.youtube.com/embed/GyHMssWe6GM?si=pvNaAg6ybnKaLNnf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
          title:
            '1월 10일 한동훈 비상대책위원장, 부산 남포동 비프(BIFF) 광장 방문',
          date: '2024. 1. 11.',
        },
        {
          imgUrl: require('@/assets/background/video2.png'),
          iframe:
            '<iframe width="316" height="238" src="https://www.youtube.com/embed/bbudIkEBNJU?si=faGh9H5mAFcm6VLE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
          title:
            '1월 4일 한동훈 비상대책위원장, 국립 5.18 민주묘지 참배',
          date: '2024. 1. 4.',
        },
        {
          imgUrl: require('@/assets/background/video1.png'),
          iframe:
            '<iframe width="316" height="238" src="https://www.youtube.com/embed/ENC2FYqTZ7s?si=PWdD00MzrCL6ydpZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
          title: '윤재옥 원내대표 2024 신년 인사',
          date: '2023. 12. 29.',
        },
      ],
      cardNewsList: [
        {
          imgUrl: require('@/assets/background/card1.jpeg'),
          open: false,
          title: '함께 가면 길이 됩니다',
        },
        {
          imgUrl: require('@/assets/background/card2.jpg'),
          open: false,
          title: '[2024년 예산] 민생과 약자 최우선',
        },
        {
          imgUrl: require('@/assets/background/card3.jpg'),
          open: false,
          title: '우주항공청 설립 국회 통과!',
        },
        {
          imgUrl: require('@/assets/background/card4.jpg'),
          open: false,
          title: '"의정 보고회" 여는 국회의원 홍문표',
        },
      ],
    };
  },
  methods: {
    handleScroll() {
      document.getElementById('my-title').style.transform = `translateY(${
        window.scrollY / 2
      }px)`;
      document.getElementById('header').style.transform = `translateY(${
        window.scrollY / 1.2
      }px)`;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    document.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
